/* NOTE: this needs to be kept in sync with neats-public-portal */

/*
  rules regarding overrides:
  1. only override in here when you can't change it in the sass
  2. don't override here if you can set the style directly in your component
  3. always put comments against why you're overriding a particular style
  4. obviously only global overrides go in here
  5. this file should be kept small
*/

/* ---------- general overrides ---------- */

/* ---------- kendo overrides ---------- */
/* please do most of this in the sass! */

/* override kendo grid link behaviour, by default links just look like normal text - note this is being done in the grid body only otherwise column menu controls are affected */
/* TODO track https://github.com/telerik/kendo-themes/issues/1481 and remove grid anchor overrides when the issue is closed out */
.k-table-tbody a {
    color: var(--bs-link-color);
    text-decoration: underline;
}

.k-table-tbody a:hover {
    color: var(--bs-link-hover-color);
    text-decoration: underline;
}

/* relocate grid paging controls to top - see https://www.telerik.com/forums/move-pagination-to-top-of-grid */
/* note that at present the Kendo recommended approach is to render a custom pager above the grid, but this override is much simpler given that we always want paging control to appear at the top */
.k-grid-pager {
    order: -1;
    border-width: 0 0 1px;
}
